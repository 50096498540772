<template>
  <div class="createAccount">
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>账户管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/webUser'}">账号管理</el-breadcrumb-item>
          <el-breadcrumb-item>创建账号</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- mainBox  :rules="rules" -->
      <div class="createMain">
          <div class="createForm">
              <el-form :model="accountForm" :rules="rules" ref="accountForm" label-width="160px" label-position="left" size="max">
                    <el-form-item label="检测机构" style="margin-top:40px;" prop="checkOrg">
                        <el-select v-model="accountForm.checkOrg" filterable clearable placeholder="请选择" @focus="slectOrg" @clear="clear">
                        <el-option
                            v-for="(item,index) in orgList"
                            :key="index"
                            :label="item.orgName"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="省市">
                      <el-cascader
                        style="width:100%"
                        v-model="accountForm.city"
                        :options="district"
                        :props="{ expandTrigger: 'hover' }"
                        @change="selectCity"
                        >
                     </el-cascader>
                  </el-form-item>
                    <el-form-item label="用户名" style="margin-top:40px;" prop="name">
                        <el-input v-model="accountForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" style="margin-top:40px;" prop="mobile">
                        <el-input v-model="accountForm.mobile"></el-input>
                    </el-form-item>
                      <el-form-item label="密码" style="margin-top:40px;" prop="password">
                        <el-input v-model="accountForm.password" type="password" show-password></el-input>
                    </el-form-item>
                      <el-form-item label="邮箱" style="margin-top:40px;">
                        <el-input v-model="accountForm.email" type="email"></el-input>
                    </el-form-item>
                </el-form>
                <!-- 添加创建机构信息 -->
                <div class="addBox">
                    <div class="add" @click="addNewOrg">+创建机构信息</div>
                    <p class="tip"><span style="color:red">*</span><span>若没有机构信息则需要先创建机构信息才可添加账号</span></p>
                </div>
          </div>
          <div class="eventEl">
            <div class="cancell" @click="noCreate">取消</div>
            <div class="cancell" style="background-color: #00CCCC;color:#fff;margin-left:24px" @click="onCreate('accountForm')">创建</div>
        </div>
      </div>
  </div>
</template>

<script>
import {createAccount} from '@/api/accountController.js'
import {getOrgList} from '@/api/orgController.js'
import  {district} from '../../utils/cityList.js'

export default {
    data(){
        //校验规则
        //name
        var checkName = (rule, value, callback) => {
         if (!value) {
          return callback(new Error('请输入名字'));
            }
            else{
                return callback()
            }
        };
        //idCard
        var checkPsw = (rule, value, callback) => {
         if (!value) {
          return callback(new Error('密码不能为空'));
            }
            else{
                return callback()
            }
        };
        //phone
        var checkPhone = (rule, value, callback) => {
         let reg =/^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
                if (!value) {
                    callback(new Error('手机号不能为空'));
                } else {
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    }
                      callback();
             }
        };
        return{
            district,//jison
            accountForm:{
                checkOrg:null,//submit
                city:null,
                cityCode:null,//submit
                proCode:null,//submit
                name:null,//submit
                password:null,//submit
                mobile:null,//submit
                email:null,//submit
            },
            pageSize: 1000,
            pageNum: 1,
            total: 0,
            orgList:[],
            //rules
            rules:{
                name:[{required: true,validator:checkName, trigger: 'blur' }],
                password:[{ required: true,validator: checkPsw, trigger: 'blur' }],
                mobile:[{required: true,validator: checkPhone, trigger: 'blur' }],
               // email:[{required: true,type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }]
                }
            }
    },
    mounted(){
        this.slectOrg();
    },
    methods:{
        //选择机构
      slectOrg(){
         // console.log("开始选择机构");
          getOrgList({
            "currentPage":this.pageNum,
            "pageSize":this.pageSize,
          }).then(res=>{
            if(res.data.status == 200){
              const orgListF =res.data.data.records;
              const newOrgList =[];
              orgListF.forEach((curr,index)=>{
                  if(curr.orgType == "检测机构"){
                      newOrgList.push(curr);
                  }
              });
              // this.orgList = this.orgList.concat(orgListF);// 是否需要并接，还未知
              this.orgList = newOrgList;
              this.totalPage = res.data.data.pages;
              this.total =res.data.data.total;
            
            }else{
                this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
      },
       //清空所选机构
        clear(){
            this.accountForm.checkOrg = null;
        },
      //选择地区
        selectCity(city){
            this.accountForm.cityCode =city[1];
            this.accountForm.proCode = city[0];
        },
        //确认创建帐户
        onCreate(formName){
            //console.log("提交了",this.accountForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    createAccount({
                        orgId:this.accountForm.checkOrg,
                        username:this.accountForm.name,
                        password:this.accountForm.password,
                        mobile:this.accountForm.mobile,
                        userEmail:this.accountForm.email,
                        provinceCode:this.accountForm.proCode,
                        cityCode:this.accountForm.cityCode
                    }).then(res=>{
                        if(res.data.status == 200){
                            this.$message({
                            message: '创建账号成功！',
                            type: 'success'
                            });
                            //other
                            this.$router.push({path:'/accountController'});
                            //clear
                            for(var el in this.accountForm) {
                                this.accountForm[el] = null;
                            }
                        }else{
                                this.$message.error(res.data.message);
                            }
                    }).catch(res=>{
                        this.$message.error(res);
                    });
                } 
                else {
                    this.$message.error('填写有误，请重新填写！');
                    return false;
                }
            });

        },
        //取消创建账户
        noCreate(){
            //clear
            for(var el in this.accountForm) {
                this.accountForm[el] = null;
            }
        },
        //创建机构
        addNewOrg(){
            this.$router.push({
                path:'/orgController'
            })
        },
        
    }
}
</script>

<style lang="stylus">
//component
.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
.el-button--goon {
  color: #FFF;
  background-color: #00CCCC;
  
}
.el-button--goon:focus,
.el-button--goon:hover {
  background: #00CCCC;
  border-color: #00CCCC;
  color: #fff;
}
.el-form-item__label{
    color:#AEAEAE !important
}
.tip{
    font-size:14px 
    font-family:SourceHanSansCN-Regular
    color:#AEAEAE
}
//normal
.createAccount
    overflow hidden
    .createMain
        overflow hidden
        height: 100vh
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .createForm
            width: 80%
            display: flex
            margin: 76px auto 0 auto 
            .addBox
                margin-top: 80px
                margin-left: 100px
                text-align: left
                .add
                    width: 148px 
                    height: 36px 
                    border: 1px solid #AEAEAE
                    line-height: 36px 
                    cursor pointer
                    text-align: center
                    font-size: 16px
                    color: #AEAEAE
                    border-radius: 4px
                    font-family: Source Han Sans CN
        .eventEl
            display: flex
            margin-left: 120px
            margin-top: 120px
            .cancell
                width: 100px
                height 40px
                line-height: 40px
                font-family: Source Han Sans CN
                font-size: 18px
                color: #858585
                text-align: center
                background-color: #F4F4F4
                cursor pointer
    

</style>